





















import { Component, Prop, Vue } from 'vue-property-decorator'
import { BCard, BButton } from '@bossabox-code/design-system'

import MetricsModal from './MetricsModal.vue'

@Component({
  components: {
    BCard,
    BButton,
    MetricsModal,
  },
})
export default class EmptyCard extends Vue {
  $refs!: {
    metricModal: HTMLFormElement
  }

  @Prop({ type: Array, required: false })
  private typesThatCanBeCreated!: string[]

  addMetric() {
    this.$refs.metricModal.openModal({
      typesThatCanBeCreated: this.typesThatCanBeCreated,
    })
  }
}
