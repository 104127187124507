





















import { Component, Vue } from 'vue-property-decorator'
import { ProjectModule } from '@/store/modules/ProjectModule'
import CallToAction from './components/call-to-action/CallToAction.vue'
import Goals from './components/goals/Goals.vue'
import Mission from './components/mission/Mission.vue'
import OtherMetrics from './components/other-metrics/OtherMetrics.vue'

@Component({
  components: {
    Goals,
    Mission,
    CallToAction,
    OtherMetrics,
  },
})
export default class Results extends Vue {
  private projectStore = ProjectModule

  private isFirstAccess = true

  async beforeMount() {
    const { projectId } = this.$route.params
    await this.projectStore.requestProject(projectId)
  }

  setIsFirstAccess(value: boolean) {
    this.isFirstAccess = value
  }
}
