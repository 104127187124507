import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import CreatePassword from '../views/auth/CreatePassword.vue'
import CreatePasswordRequested from '../views/auth/CreatePasswordRequested.vue'
import Csat from '../views/csat/Csat.vue'
import Dashboard from '../views/projects/dash-board/Dashboard.vue'
import EmailCreatePassword from '../views/auth/EmailCreatePassword.vue'
import ExpandedBoard from '../views/projects/ExpandedBoard.vue'
import ExpiredPassword from '../views/auth/ExpiredPassword.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ForgotPasswordRequested from '../views/auth/ForgotPasswordRequested.vue'
import ListProjects from '../views/projects/ListProjects.vue'
import Login from '../views/auth/Login.vue'
import PasswordCreated from '../views/auth/PasswordCreated.vue'
import ProjectPeople from '../views/projects/people/ProjectPeople.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'
import ResetPasswordUpdated from '../views/auth/ResetPasswordUpdated.vue'
import SecondaryMenu from '../layouts/SecondaryMenu.vue'
import ReportPreview from '../views/projects/ReportPreview.vue'
import Goal from '../views/projects/results/components/goals/Goal.vue'
import Results from '../views/projects/results/Results.vue'
import TaskManager from '../views/projects/task-manager/TaskManager.vue'
import TaskManagerAddTask from '../views/projects/task-manager/AddTask.vue'
import TaskManagerEditTask from '../views/projects/task-manager/EditTask.vue'
import { getEventNameByRouter } from '../utils/pageViewNames'

import store from '../store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/password/create',
    name: 'CreatePassword',
    component: CreatePassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/password/create/email',
    name: 'EmailCreatePassword',
    component: EmailCreatePassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/password/success',
    name: 'PasswordCreated',
    component: PasswordCreated,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/password/forgot',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/password/forgot/requested/:email',
    name: 'ForgotPasswordRequested',
    component: ForgotPasswordRequested,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/password/create/requested/:email',
    name: 'CreatePasswordRequested',
    component: CreatePasswordRequested,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/password/reset',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/password/reset/success',
    name: 'ResetPasswordUpdated',
    component: ResetPasswordUpdated,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/password/create/expired',
    name: 'ExpiredCreatePassword',
    component: ExpiredPassword,
    meta: {
      source: 'create',
      requiresAuth: false,
    },
  },
  {
    path: '/password/reset/expired',
    name: 'ExpiredResetPassword',
    component: ExpiredPassword,
    meta: {
      source: 'reset',
      requiresAuth: false,
    },
  },
  {
    path: '/csat',
    name: 'Csat',
    component: Csat,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/projects',
    name: 'list-projects',
    component: ListProjects,
    meta: {
      requiresAuth: true,
      layout: 'navbar',
    },
  },
  {
    path: '/projects/:projectId/dashboard',
    name: 'project-dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      layout: 'navbar',
      subMenu: {
        layout: SecondaryMenu,
      },
    },
  },
  {
    path: '/projects/:projectId/results/:goalId',
    name: 'goals',
    component: Goal,
    meta: {
      requiresAuth: true,
      layout: 'navbar',
      subMenu: {
        layout: SecondaryMenu,
      },
    },
  },
  {
    path: '/projects/:projectId/results',
    name: 'results',
    component: Results,
    meta: {
      requiresAuth: true,
      layout: 'navbar',
      subMenu: {
        layout: SecondaryMenu,
      },
    },
  },
  {
    path: '/projects/:projectId/task-manager',
    name: 'task-manager',
    component: TaskManager,
    meta: {
      requiresAuth: true,
      layout: 'navbar',
      subMenu: {
        layout: SecondaryMenu,
      },
    },
  },
  {
    path: '/projects/:projectId/task-manager/:boardId/add',
    name: 'task-manager-add-task',
    component: TaskManagerAddTask,
    meta: {
      requiresAuth: true,
      layout: 'close-header-basic',
    },
  },
  {
    path: '/projects/:projectId/task-manager/:boardId/:taskId',
    name: 'task-manager-edit-task',
    component: TaskManagerEditTask,
    meta: {
      requiresAuth: true,
      layout: 'close-header-basic',
    },
  },
  {
    path: '/projects/:projectId/people',
    name: 'project-people',
    component: ProjectPeople,
    meta: {
      requiresAuth: true,
      layout: 'navbar',
      subMenu: {
        layout: SecondaryMenu,
      },
    },
  },
  {
    path: '/projects/:projectId/expanded',
    name: 'expanded-board',
    component: ExpandedBoard,
    meta: {
      requiresAuth: true,
      layout: 'close-header-basic',
    },
  },
  {
    path: '/projects/:projectId/report-preview',
    name: 'report-preview',
    component: ReportPreview,
    meta: {
      requiresAuth: true,
      layout: 'close-header-basic',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const eventToLog = to && to.name && getEventNameByRouter(to.name)
  const { params, query } = to

  if (query.authToken) {
    await store.dispatch('authModule/doExternalLoginAuth', {
      token: query.authToken,
      redirectRoute: to.path,
    })
  }

  if (Vue.prototype.$tracking && eventToLog) {
    Vue.prototype.$tracking.logEvent(eventToLog, params)
  }

  const routeNeedsAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = !!localStorage.getItem('@token')
  if (routeNeedsAuth) {
    if (isAuthenticated) next()
    else
      next({
        path: '/',
        params: { nextUrl: to.fullPath },
      })
  } else next()
})

export default router
