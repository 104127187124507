import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/store'
import authAPI from '@/services/auth'
import api from '@/services/customer'
import { UserModule } from '../UserModule'
import router from '@/router'

export interface IAuthModule {
  errorCode: string
}
export interface IResponseErrorCode {
  code: string
}
export interface IResponseError {
  error: IResponseErrorCode
}
export interface IResponseData {
  data: IResponseError
}
export interface IAxiosErrorResponse {
  response: IResponseData
}
export interface ILoginResponse {
  token: string
}
export interface IAuthAPIParameters {
  email: string
  password: string
}

export interface IExternalAuthAPIParameters {
  token: string
  redirectRoute: string
}

@Module({ dynamic: true, namespaced: true, name: 'authModule', store })
export class Auth extends VuexModule implements IAuthModule {
  public errorCode = ''

  public loading = false

  @Action
  public async doLoginAuth(payload: IAuthAPIParameters): Promise<void> {
    try {
      this.setLoading(true)

      const { email, password }: IAuthAPIParameters = payload
      const {
        data: { token },
      } = await authAPI.post('/auth/login', {
        email: email,
        password: password,
      })

      const { data } = await api.post('/v1/sessions', {
        token,
      })

      if (token && data.id) {
        localStorage.setItem('@token', token)
        localStorage.setItem('@customer_id', data.id)

        if (data.company.id)
          localStorage.setItem('@company_id', data.company.id)

        UserModule.setUserData(data)
        this.setLoading(false)

        router.push({ name: 'list-projects' })
      }
    } catch (err) {
      const {
        response: {
          data: {
            error: { code },
          },
        },
      }: IAxiosErrorResponse = err
      this.setErrorMessage(code)

      this.setLoading(false)
    }
  }

  @Action
  public async doExternalLoginAuth(
    params: IExternalAuthAPIParameters
  ): Promise<void> {
    const { token, redirectRoute } = params
    try {
      const { data } = await api.post('/v1/sessions', {
        token,
      })

      if (token && data.id) {
        localStorage.setItem('@token', token)
        localStorage.setItem('@customer_id', data.id)

        if (data.company.id)
          localStorage.setItem('@company_id', data.company.id)

        UserModule.setUserData(data)
        router.replace(redirectRoute)
      }
    } catch {
      router.replace({ name: 'Login' })
    }
  }

  @Action
  public signOut(): void {
    localStorage.removeItem('@token')
    localStorage.removeItem('@customer_id')

    router.push('/')
  }

  @Mutation
  public setLoading(loading: boolean): void {
    this.loading = loading
  }

  @Mutation
  public setErrorMessage(errorCode: string) {
    const messages = new Map<string, string>([
      ['WRONG_CREDENTIALS', 'Usuário/senha incorreto'],
      ['NOT_EMAIL_USER', 'Login por e-mail não encontrado, tente pelo Google'],
    ])
    this.errorCode =
      messages.get(errorCode) || 'Ocorreu um erro, tente novamente!'
  }
}
export const AuthModule = getModule(Auth)
