


























import { Component, Vue, Prop } from 'vue-property-decorator'
import { IGoal } from '@/store/modules/ProjectGoalsModule'
import { BButton, BTable } from '@bossabox-code/design-system'

@Component({
  components: {
    BTable,
    BButton,
  },
})
export default class GoalsTable extends Vue {
  public fields = [
    {
      key: 'objective',
      value: 'Objetivo',
    },
    {
      key: 'keys',
      value: 'Resultados-chave',
    },
    {
      key: 'actions',
      value: '',
    },
  ]

  @Prop({ type: Array, default: () => [] })
  public goals!: IGoal

  showDetails(goal: IGoal) {
    const { projectId } = this.$route.params

    this.$router.push({
      name: 'goals',
      params: {
        projectId: projectId,
        goalId: goal.id,
      },
    })
  }
}
