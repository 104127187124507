import { ERoutesCategory } from './ERoutesCategory'

interface IProps {
  params: {
    projectName: string
    projectId: string
  }
}

export interface ITab {
  path: string
  name: string
  title: string
  category: string
}

export function tabsList(props: IProps) {
  const PROJECT_ROUTE_PATH = `/projects/${props.params.projectId}`

  /**
   * Quando route name for project-dashboard ou task-manager
   * essa lista deverá ser utilizada
   */
  const defaultListTabs: ITab[] = [
    {
      title: 'Visão geral',
      name: 'project-dashboard',
      path: `${PROJECT_ROUTE_PATH}/dashboard`,
      category: ERoutesCategory.PROJECT_DASHBOARD,
    },
    {
      title: 'Minhas Tarefas',
      name: 'task-manager',
      path: `${PROJECT_ROUTE_PATH}/task-manager`,
      category: ERoutesCategory.PROJECT_DASHBOARD,
    },
    {
      title: 'Resultados',
      name: 'results',
      path: `${PROJECT_ROUTE_PATH}/results`,
      category: ERoutesCategory.RESULTS,
    },
    {
      title: 'Pessoas',
      name: 'people',
      path: `${PROJECT_ROUTE_PATH}/people`,
      category: ERoutesCategory.PEOPLE,
    },
  ]

  /**
   * Quando route name for diferente project-dashboard e task-manager
   * essa lista deverá ser utilizada
   */
  const listTabs: ITab[] = [
    {
      title: 'Visão geral',
      name: 'project-dashboard',
      path: `${PROJECT_ROUTE_PATH}/dashboard`,
      category: ERoutesCategory.PROJECT_DASHBOARD,
    },
    {
      title: 'Minhas Tarefas',
      name: 'task-manager',
      path: `${PROJECT_ROUTE_PATH}/task-manager`,
      category: ERoutesCategory.PROJECT_DASHBOARD,
    },
    {
      title: 'Missão e OKRs',
      name: 'results',
      path: `${PROJECT_ROUTE_PATH}/results`,
      category: ERoutesCategory.RESULTS,
    },
    {
      title: 'Time Alocado',
      name: 'project-people',
      path: `${PROJECT_ROUTE_PATH}/people`,
      category: ERoutesCategory.PEOPLE,
    },
  ]

  return {
    listTabs,
    defaultListTabs,
  }
}
