













































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { BModal, BIcon, BDoneIcon, BAvatar } from '@bossabox-code/design-system'
import { ISquadMembers } from '@/store/modules/ProjectModule'

@Component({
  components: {
    BIcon,
    BModal,
    BDoneIcon,
    BAvatar,
  },
})
export default class MemberInfoModal extends Vue {
  $refs!: {
    memberModal: HTMLFormElement
  }

  @Prop({ type: Boolean, default: false })
  private openOrClosedModal!: boolean

  @Prop({ type: Object, default: false })
  public member!: ISquadMembers

  @Watch('openOrClosedModal')
  showModal() {
    this.openOrClosedModal
      ? this.$refs.memberModal.showModal()
      : this.$refs.memberModal.hideModal()
  }

  afterClosed() {
    this.$emit('afterClosed')
  }

  generateShareableLink(link: string) {
    return `${process.env.VUE_APP_BOSSA_FRONT_URL}/u/${link}`
  }
}
