





















import { Component, Vue } from 'vue-property-decorator'
import { ProjectModule } from '@/store/modules/ProjectModule'
import { BIcon, BArrowBackIcon } from '@bossabox-code/design-system'

@Component({
  components: {
    BIcon,
    BArrowBackIcon,
  },
})
export default class ProductTitle extends Vue {
  private projectStore = ProjectModule
}
