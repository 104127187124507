




































import { Component, Vue, Watch } from 'vue-property-decorator'

import EmptyCard from './EmptyCard.vue'
import MetricCard from './MetricCard.vue'
import MetricsModal from './MetricsModal.vue'
import { ProjectOtherMetricsModule } from '@/store/modules/ProjectOtherMetricsModule'

import {
  BIcon,
  BCard,
  BSelect,
  BButton,
  BInfoIcon,
  BRefreshIcon,
  BBoxTooltip as BTooltip,
} from '@bossabox-code/design-system'

import { metricTypes, metricTypesKeys } from './utils/MapMetricTypes'

@Component({
  components: {
    BIcon,
    BCard,
    BButton,
    BSelect,
    BTooltip,
    EmptyCard,
    BInfoIcon,
    MetricCard,
    MetricsModal,
    BRefreshIcon,
  },
})
export default class OtherMetrics extends Vue {
  private metricsStore = ProjectOtherMetricsModule

  private metricsThatCanBeCreated: string[] = metricTypesKeys

  async beforeMount() {
    const { projectId } = this.$route.params
    await this.metricsStore.requestMetrics(projectId)
  }

  @Watch('metricsStore.metrics')
  setMetricsThatCanBeCreated() {
    this.updateMetricsThatCanBeCreated()
  }

  updateMetricsThatCanBeCreated() {
    const metricsTypesCreated = this.metricsStore.metrics.map(
      metric => metric.type
    )

    this.metricsThatCanBeCreated = metricTypesKeys
      .filter(type => {
        if (!metricsTypesCreated.includes(type)) return type
      })
      .map(type => metricTypes[type])
  }

  get canCreateMoreMetrics() {
    return this.metricsThatCanBeCreated.length
  }
}
