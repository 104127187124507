




































import { Component, Prop, Vue } from 'vue-property-decorator'
import { BIcon, BButton, BCreateIcon } from '@bossabox-code/design-system'
import Card from '@/components/Card.vue'
import MetricsModal from './MetricsModal.vue'

import {
  metricTypes,
  getMetricSubtiblesAndExamples,
} from './utils/MapMetricTypes'
import { IMetric } from '@/store/modules/ProjectOtherMetricsModule/interfaces'

@Component({
  components: {
    Card,
    BIcon,
    BButton,
    BCreateIcon,
    MetricsModal,
  },
})
export default class MetricCard extends Vue {
  $refs!: {
    metricModal: HTMLFormElement
  }

  private title = ''

  private subtitle = ''

  @Prop({ type: Object, required: true })
  private metric!: IMetric

  beforeMount() {
    const metricTypeFormated = metricTypes[this.metric.type]
    const metric = getMetricSubtiblesAndExamples(metricTypeFormated)

    if (metric) {
      this.title = metricTypeFormated
      this.subtitle = metric.subtitle
    }
  }

  editMetric() {
    this.$refs.metricModal.openModal({ metric: this.metric })
  }
}
