import { decode } from 'jsonwebtoken'

interface ITokenPayload {
  iat: number
  exp: number
  sub: string
}

export default function tokenIsValid(token: string) {
  const decodedToken = decode(token, { complete: true })
  if (!decodedToken) return false
  const { exp } = decodedToken as ITokenPayload
  if (Date.now() >= exp * 1000) {
    return false
  } else {
    return true
  }
}
