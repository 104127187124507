import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/store'
import api from '@/services/customer'

export interface IProjectReportParams {
  projectId: string
  additionalInformations: string
}

@Module({
  dynamic: true,
  namespaced: true,
  name: 'projectReportModule',
  store,
})
export class ProjectReport extends VuexModule {
  public projectReport = ''

  public error = false

  public loading = true

  @Action
  public async requestProjectReport(
    params: IProjectReportParams
  ): Promise<void> {
    try {
      this.setLoading(true)

      const { data: file } = await api.post(
        `/v1/projects/${params.projectId}/report`,
        {
          projectId: params.projectId,
          additionalInformations: params.additionalInformations,
        },
        { responseType: 'blob' }
      )

      const fileBlob = new Blob([file], { type: 'application/pdf' })
      const fileURL = window.URL.createObjectURL(fileBlob)

      this.setLoading(false)
      this.setError(false)
      this.setProjectReport(fileURL)
    } catch (error) {
      this.setLoading(false)
      this.setError(true)
    }
  }

  @Mutation
  public setError(error: boolean): void {
    this.error = error
  }

  @Mutation
  public setLoading(loading: boolean): void {
    this.loading = loading
  }

  @Mutation
  public setProjectReport(projectReport: string): void {
    this.projectReport = projectReport
  }

  public get getProjectReport() {
    return this.projectReport
  }

  public get getLoading() {
    return this.loading
  }

  public get getError() {
    return this.error
  }
}

export const ProjectReportModule = getModule(ProjectReport)
