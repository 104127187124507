





















































import { Component, Vue } from 'vue-property-decorator'
import {
  BButton,
  BErrorOutlineIcon,
  BExpandIcon,
  BIcon,
} from '@bossabox-code/design-system'
import Card from '@/components/Card.vue'
import ProductProgressInfoModal from '@/views/projects/components/ProductProgressInfoModal.vue'

import { ProjectModule } from '@/store/modules/ProjectModule'
import { UserModule } from '@/store/modules/UserModule'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import ptBR from 'date-fns/locale/pt-BR'

@Component({
  components: {
    Card,
    BIcon,
    BButton,
    BExpandIcon,
    BErrorOutlineIcon,
    ProductProgressInfoModal,
  },
})
export default class ProductProgress extends Vue {
  private updateAt!: Date

  private modalIsOpen = false

  private projectStore = ProjectModule

  private userStore = UserModule

  private boardLink: string | null = null

  private lastUpdatedAt = 'Atualizado agora mesmo.'

  async beforeMount() {
    this.updateAt = new Date()
    this.defineBoardLink()
  }

  mounted() {
    window.setInterval(() => {
      this.boardLastUpdate()
    }, 30000)
  }

  boardLastUpdate() {
    const dateTime = formatDistanceToNow(this.updateAt, { locale: ptBR })

    this.lastUpdatedAt = `Atualizado ${dateTime} atrás.`
  }

  defineBoardLink() {
    if (this.projectStore.project?.boardLink)
      this.boardLink = this.projectStore.project.boardLink
  }

  async updateBoard() {
    this.boardLink = null
    this.updateAt = new Date()

    await this.$nextTick()

    this.defineBoardLink()
    this.lastUpdatedAt = 'Atualizado agora mesmo.'
  }

  goToExpanded() {
    const project = this.projectStore.project

    if (project?.boardLink)
      this.$router.push({
        name: 'expanded-board',
        params: { projectId: project.id },
      })
  }

  handleModal() {
    this.modalIsOpen = this.modalIsOpen === false ?? true
  }
}
