














import { Component, Vue } from 'vue-property-decorator'
import { BNewBIconDone } from '@bossabox-code/design-system'

@Component({
  components: {
    BNewBIconDone,
  },
})
export default class CsatAlreadyAnswered extends Vue {}
