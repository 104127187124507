import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  install as Toast,
  BCheckCircleIcon,
  BErrorIcon,
  BClearIcon,
} from '@bossabox-code/design-system'
import '@/layouts'

import Tracking from '@/plugins/tracking'
import Hotjar from 'vue-hotjar'

Vue.config.productionTip = false

Vue.use(Toast)
Vue.use(Tracking)

if (process.env.VUE_APP_COMPANIES_HOTJAR_API_KEY) {
  Vue.use(Hotjar, {
    id: process.env.VUE_APP_COMPANIES_HOTJAR_API_KEY,
    isProduction: true,
    snippetVersion: 6,
  })
}

Vue.component('icon-check-circle', BCheckCircleIcon)
Vue.component('icon-error', BErrorIcon)
Vue.component('icon-clear', BClearIcon)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
