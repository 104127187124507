import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/store'
import api from '@/services/customer'

export interface ISquadMembers {
  id: string | number
  fullName: string
  role: string
  profilePicture: string
  profileShareableLinkName: string
  roleDescription: string
  roleTasks: Array<string>
}

export interface ISprint {
  id: string
  name: string
  goal?: string
  apiId: string
  status: {
    description: string
    type: string
  }
  dueDate: string | null
  startDate: string | null
  createdAt: string
  updatedAt: string
}

export interface IProductJobAppliance {
  id: string
  user: string
  favorite: boolean
  createdAt: string
  updatedAt: string
}

interface IApplicationForm {
  id: string
  deleted: boolean
  question: string
}

export interface IProductJob {
  id: string
  status: string
  role: string
  skills: string[]
  appliances: IProductJobAppliance[]
  acceptedUser: ISquadMembers | null
  additionalInformation: string
  applicationForm: IApplicationForm[] | []
  startDate: string
  createdAt: string
  updatedAt: string
}

export interface IProject {
  id: string
  apiId?: string
  name: string
  logo: string | null
  description: string
  boardLink: string | null
  phase: string | null
  currentSprint: ISprint | null
  jobs: IProductJob[]
  squad: ISquadMembers[] | []
  image?: string
  mission?: string
  indicators?: string
  immersionDate?: string | null
}

@Module({ dynamic: true, namespaced: true, name: 'projectModule', store })
export class Project extends VuexModule {
  public userProjects: IProject[] = []

  public project: IProject | null = null

  public error = ''

  public loading = true

  @Action
  public async requestUserProjects(userId: string): Promise<void> {
    try {
      this.setLoading(true)

      const { data: projects } = await api.get(`/v1/projects?id=${userId}`)

      this.setUserProjects(projects)
      this.setLoading(false)
    } catch (err) {
      const {
        response: {
          data: { message },
        },
      } = err

      this.setError(message)
      this.setLoading(false)
    }
  }

  @Action
  public async requestProject(projectId: string): Promise<void> {
    try {
      this.setLoading(true)

      const { data: project } = await api.get(`/v1/projects/${projectId}`)

      this.setProject(project)
      this.setLoading(false)
    } catch (err) {
      const {
        response: {
          data: { message },
        },
      } = err

      this.setError(message)
      this.setLoading(false)
    }
  }

  @Mutation
  public setError(error: string): void {
    this.error = error
  }

  @Mutation
  public setLoading(loading: boolean): void {
    this.loading = loading
  }

  @Mutation
  public setUserProjects(projects: IProject[]): void {
    this.userProjects = projects
  }

  @Mutation
  public setProject(project: IProject | null): void {
    this.project = project
  }

  public get getUserProjects() {
    return this.userProjects
  }

  public get getProject() {
    return this.project
  }

  public get getLoading() {
    return this.loading
  }

  public get getError() {
    return this.error
  }
}

export const ProjectModule = getModule(Project)
