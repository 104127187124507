










































import { format, parseISO } from 'date-fns'
import { Component, Vue } from 'vue-property-decorator'
import {
  BIcon,
  BButton,
  BCalendarTodayIcon,
  BAssignmentIndIcon,
} from '@bossabox-code/design-system'
import Card from '@/components/Card.vue'
import { ProjectModule } from '@/store/modules/ProjectModule'

@Component({
  components: {
    Card,
    BIcon,
    BButton,
    BCalendarTodayIcon,
    BAssignmentIndIcon,
  },
})
export default class SprintStatus extends Vue {
  private project = ProjectModule.getProject

  formatDate(date: string) {
    return (date && format(parseISO(date), 'dd/MM')) || ''
  }
}
