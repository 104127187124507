import { ERoutesCategory } from './ERoutesCategory'

interface IProps {
  params: {
    projectName: string
    projectId: string
  }
}

export interface IBreadcrumbs {
  name: string
  path: string
}

export function breacrumbsList(props: IProps) {
  const PROJECT_ROUTE_PATH = `/projects/${props.params.projectId}`

  const initialBreadcrumbs = [
    {
      name: 'Projetos',
      path: `/projects`,
    },
    {
      name: props.params.projectName || '',
      path: `${PROJECT_ROUTE_PATH}/dashboard`,
    },
  ]

  const breacrumbsList: { [key: string]: IBreadcrumbs[] } = {
    [ERoutesCategory.TASK_MANAGER]: initialBreadcrumbs,
    [ERoutesCategory.PROJECT_DASHBOARD]: initialBreadcrumbs,
    [ERoutesCategory.RESULTS]: [
      ...initialBreadcrumbs,
      {
        name: 'Resultados',
        path: `${PROJECT_ROUTE_PATH}/results`,
      },
    ],
    [ERoutesCategory.PEOPLE]: [
      ...initialBreadcrumbs,
      {
        name: 'Pessoas',
        path: `${PROJECT_ROUTE_PATH}/people`,
      },
    ],
  }

  return breacrumbsList
}
