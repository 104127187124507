








































































































import { format, parseISO } from 'date-fns'
import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  BIcon,
  BAvatar,
  BRefreshIcon,
  BLocationOnIcon,
  BChevronRightIcon,
  BCalendarTodayIcon,
  BAssignmentIndIcon,
} from '@bossabox-code/design-system'
import Card from '@/components/Card.vue'

import { ProjectModule } from '@/store/modules/ProjectModule'
import { UserModule } from '@/store/modules/UserModule'

@Component({
  components: {
    Card,
    BAvatar,
    BIcon,
    BLocationOnIcon,
    BChevronRightIcon,
    BCalendarTodayIcon,
    BAssignmentIndIcon,
    BRefreshIcon,
  },
})
export default class ListProjects extends Vue {
  private userStore = UserModule

  private projectStore = ProjectModule

  async beforeMount() {
    const customerId = localStorage.getItem('@customer_id')
    if (customerId) await this.projectStore.requestUserProjects(customerId)
    this.projectStore.setProject(null)
  }

  @Watch('userStore.getCustomer')
  async observableCustomer() {
    const user = this.userStore.getCustomer
    const { id } = user

    if (Vue.prototype.$hj && !Vue.prototype.$hjInitialized) {
      Vue.prototype.$hj('identify', id, {
        userEmail: user?.email,
        companyName: user?.company?.name,
      })

      Vue.prototype.$hjInitialized = true
    }

    if (!this.projectStore.getUserProjects.length && id) {
      await this.projectStore.requestUserProjects(id)
    }
  }

  formatDate(date: string) {
    return (date && format(parseISO(date), 'dd/MM')) || ''
  }
}
