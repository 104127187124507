


















































import { Component, Vue } from 'vue-property-decorator'
import {
  BButton,
  BLink,
  BIcon,
  BBoxLogoIcon,
} from '@bossabox-code/design-system'

import { UserModule } from '@/store/modules/UserModule'

@Component({
  components: {
    BButton,
    BLink,
    BIcon,
    BBoxLogoIcon,
  },
})
export default class ForgotPasswordRequested extends Vue {
  private email = ''
  private store = UserModule

  async forgotPassword() {
    if (this.email) {
      await this.store.doRequestForgotPassword({
        email: this.email,
      })
    }
  }

  beforeMount() {
    const { email } = this.$route.params
    if (email) this.email = email
  }
}
