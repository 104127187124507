












import { Component, Vue } from 'vue-property-decorator'
import { BNav } from '@bossabox-code/design-system'
import CsatForm from './components/CsatForm.vue'

@Component({
  components: {
    BNav,
    CsatForm,
  },
})
export default class Csat extends Vue {}
