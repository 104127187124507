interface ITypesDescription {
  [key: string]: {
    subtitle: string
    examples: string[]
  }
}
export const metricTypes: { [key: string]: string } = {
  PROFILE: 'Perfil',
  REVENUE: 'Receita',
  RETENTION: 'Retenção',
  HAPPINESS: 'Felicidade',
  SUCCESS: 'Task Success',
  AQUISITION: 'Aquisição',
  ENGAGEMENT: 'Engajamento',
}

export const parseTypeToConstant = (type: string) =>
  Object.keys(metricTypes).find(key => metricTypes[key] === type)

export const metricTypesKeys = Object.keys(metricTypes)

export const metricTypesValues = Object.values(metricTypes)

export const metricTypesDescription: ITypesDescription = {
  AQUISITION: {
    subtitle: 'Métricas relacionadas à quantidade de novos usuários.',
    examples: [
      'Quantidade de visitas na página inicial',
      'quantidade de downloads de um aplicativo',
    ],
  },
  ENGAGEMENT: {
    subtitle: 'Métricas relacionadas à interação do usuário com o produto.',
    examples: [
      'Tempo médio para completar determinado fluxo',
      'quantidade de usuários que demonstraram interesse em algo',
    ],
  },
  PROFILE: {
    subtitle:
      'Métricas relacionadas às características dos usuários que utilizam o produto.',
    examples: [
      'Quantidade de usuários por região',
      'quantidade de usuários por device',
      'quantidade de usuários por especialidade (plataforma profissional)',
      'quantidade de usuários por faixa etária',
    ],
  },
  HAPPINESS: {
    subtitle: 'Métricas relacionadas à satisfação com o uso do produto.',
    examples: ['NPS', 'Avaliações'],
  },
  RETENTION: {
    subtitle:
      'Métricas relacionadas à frequência e permanência do uso do produto.',
    examples: [
      'Quantidade de usuários ativos mensais',
      'frequência das sessões',
      'bounce-rate',
    ],
  },
  REVENUE: {
    subtitle:
      'Métricas relacionadas ao ganho financeiro de acordo com o uso do produto.',
    examples: [
      'Receita por assinatura',
      'receita por anúncios',
      'compras in-app',
    ],
  },
  SUCCESS: {
    subtitle:
      'Métricas relacionadas aos usuários completarem objetivos de forma fácil.',
    examples: [
      'Quantidade de notificações abertas',
      'conclusão de tarefas no produto',
    ],
  },
}

export function getMetricSubtiblesAndExamples(metricTypeFormatted: string) {
  const key = Object.keys(metricTypes).find(
    key => metricTypes[key] === metricTypeFormatted
  )

  if (!key) return null

  return {
    subtitle: metricTypesDescription[key].subtitle,
    examples: metricTypesDescription[key].examples,
  }
}
