


























































import { Component, Vue, Prop } from 'vue-property-decorator'
import {
  BCard,
  BButton,
  BErrorOutlineIcon,
  BExpandIcon,
  BRefreshIcon,
  BChevronRightIcon,
  BIcon,
  BArrowBackIcon,
  BProgressBar,
} from '@bossabox-code/design-system'
import Card from '@/components/Card.vue'
import { UserModule } from '@/store/modules/UserModule'
import { progressBarColor } from '@/helpers/projectGoals/averageValueOfGoals'
import ProductProgressInfoModal from '@/views/projects/components/ProductProgressInfoModal.vue'
import { IGoal, IKey } from '@/store/modules/ProjectGoalsModule'

@Component({
  components: {
    ProductProgressInfoModal,
    BCard,
    Card,
    BButton,
    BErrorOutlineIcon,
    BExpandIcon,
    BRefreshIcon,
    BIcon,
    BChevronRightIcon,
    BArrowBackIcon,
    BProgressBar,
  },
  methods: {
    progressBarColor,
  },
})
export default class Sidebar extends Vue {
  private userStore = UserModule

  @Prop({ type: String, default: '' })
  private mission!: string

  @Prop({ type: Array, required: true })
  private goals!: IGoal[]

  get keysFromGoals() {
    const keys: IKey[] = []

    this.goals.forEach(goal => {
      keys.push(...goal.keys)
    })

    return keys
  }
}
