











































import { Component, Vue } from 'vue-property-decorator'
import {
  BButton,
  BLink,
  BIcon,
  BBoxLogoIcon,
} from '@bossabox-code/design-system'
import { EMAIL_SENT } from '@/helpers/constants'
import { UserModule } from '@/store/modules/UserModule'

interface IBToast {
  show: Function
}

@Component({
  components: {
    BButton,
    BLink,
    BIcon,
    BBoxLogoIcon,
  },
})
export default class CreatePasswordRequested extends Vue {
  $toasts!: IBToast
  private email = ''
  private store = UserModule

  async requestNewPassword() {
    if (this.email) {
      await this.store.doRequestNewPassword({
        email: this.email,
      })
      const { TITLE: title, MESSAGE: message } = EMAIL_SENT.SUCCESS
      this.$toasts.show({
        title,
        message,
        timeout: 5000,
        type: 'success',
      })
    }
  }

  beforeMount() {
    const { email } = this.$route.params
    if (email) this.email = email
  }
}
