









































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { BModal, BButton, BLink } from '@bossabox-code/design-system'

import { UserModule } from '@/store/modules/UserModule'

interface IToast {
  show: Function
}

@Component({
  components: {
    BModal,
    BButton,
    BLink,
  },
})
export default class TermsAndPoliciesModal extends Vue {
  private userStore = UserModule

  $refs!: {
    termsAndPoliciesModal: HTMLFormElement
  }

  $toasts!: IToast

  @Prop({ type: Boolean, default: true })
  private openOrClosedModal!: boolean

  @Watch('openOrClosedModal')
  showModal() {
    this.openOrClosedModal
      ? this.$refs.termsAndPoliciesModal.showModal()
      : this.$refs.termsAndPoliciesModal.hideModal()
  }

  async submitTermsAndPolicesAccept() {
    try {
      this.userStore.requestUpdateCustomer({
        acceptedTerms: true,
        acceptedTermsAt: new Date(),
      })

      this.$refs.termsAndPoliciesModal.forceHideModal()
    } catch {
      this.$toasts.show({
        message: this.userStore.getError,
        timeout: 3000,
        type: 'danger',
      })
    }
  }
}
