





















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { BModal, BIcon, BDoneIcon, BAvatar } from '@bossabox-code/design-system'
import { IProductJob } from '@/store/modules/ProjectModule'
import { formatDate } from '@/helpers/formatDate'

@Component({
  components: {
    BIcon,
    BModal,
    BDoneIcon,
    BAvatar,
  },
})
export default class JobInfoModal extends Vue {
  $refs!: {
    jobModal: HTMLFormElement
  }

  @Prop({ type: Boolean, default: false })
  private openOrClosedModal!: boolean

  @Prop({ type: Object, default: false })
  public job!: IProductJob

  @Watch('openOrClosedModal')
  showModal() {
    this.openOrClosedModal
      ? this.$refs.jobModal.showModal()
      : this.$refs.jobModal.hideModal()
  }

  afterClosed() {
    this.$emit('afterClosed')
  }

  get formatedDate() {
    return formatDate(this.job.createdAt, 'dd/MM/yyyy')
  }
}
