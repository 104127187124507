

















import { Component, Vue } from 'vue-property-decorator'
import { BLink } from '@bossabox-code/design-system'
import { ProjectModule } from '@/store/modules/ProjectModule'

@Component({
  components: {
    BLink,
  },
})
export default class ExpandedBoard extends Vue {
  private projectStore = ProjectModule

  async beforeMount() {
    const { projectId } = this.$route.params
    await this.projectStore.requestProject(projectId)
  }
}
