import amplitude from 'amplitude-js'
import api from '@/services/customer'

const apiKey = process.env.VUE_APP_COMPANIES_AMPLITUDE_API_KEY || ''
let amplitudeInstance: any

interface IUser {
  id: string
  companyId?: string
  userEmail?: string
}

let initialized = false
const amplitudeService = {
  initialize(user: IUser): void {
    if (apiKey && user.id) {
      amplitudeInstance = amplitude.getInstance() // initializes default instance of Amplitude client
      amplitudeInstance.init(apiKey)
      amplitudeInstance.setUserId(user.id)
      amplitudeInstance.setUserProperties(user)

      initialized = true
      console.log('Amplitude initialized')
    }
  },

  async logEvent(eventName: string, eventProperties: Record<string, any>) {
    if (!initialized) {
      const user = await this.getUserData()
      this.initialize(user)
    }

    if (!amplitudeInstance || !initialized || apiKey?.length != 32) return

    amplitudeInstance.logEvent(eventName, eventProperties)
  },

  async getUserData(): Promise<IUser> {
    const token = localStorage.getItem('@token')
    const customerId = localStorage.getItem('@customer_id')
    const companyId = localStorage.getItem('@company_id')

    const { data } = await api.post('/v1/sessions', {
      token,
    })

    const user = {
      id: customerId || '',
      companyId: companyId || '',
      userEmail: data.email,
      companyName: data.company.name,
    }

    return user
  },
}

export default amplitudeService
