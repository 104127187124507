

















































import { Component, Vue } from 'vue-property-decorator'
import {
  BInput,
  BIcon,
  BButton,
  BContextBanner,
  BErrorIcon,
  BBoxLogoIcon,
} from '@bossabox-code/design-system'
import { UserModule } from '@/store/modules/UserModule'

@Component({
  components: {
    BInput,
    BButton,
    BIcon,
    BContextBanner,
    BErrorIcon,
    BBoxLogoIcon,
  },
})
export default class EmailCreatePassword extends Vue {
  private email = ''
  private store = UserModule

  async requestPasswordCreate() {
    if (this.email) {
      await this.store.doRequestNewPassword({
        email: this.email,
      })
    }
  }
}
