import axios from 'axios'

const api = axios.create({
  baseURL: process.env.VUE_APP_CUSTOMER_API_URL,
})

api.interceptors.request.use(async config => {
  const token = localStorage.getItem('@token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  config.headers.application = 'COMPANIES'
  return config
})

export default api
