import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/store'
import api from '@/services/customer'

export interface ICsatEmail {
  token: string | (string | null)[]
  score: number
  feedback: string
}

@Module({
  dynamic: true,
  namespaced: true,
  name: 'csatsModule',
  store,
})
export class Csats extends VuexModule {
  public error = ''
  public csatSuccessfullAnswered = false
  public loading = true

  @Action
  public async sendCsatEmailAnswer({
    token,
    score,
    feedback,
  }: ICsatEmail): Promise<void> {
    try {
      this.setLoading(true)

      await api.post(`public/v1/csats/email-answer`, { token, score, feedback })

      this.setLoading(false)
      this.setCsatSuccessfullAnswered(true)
      this.setError('')
    } catch (error) {
      console.log(error)
      const {
        response: {
          data: { message },
        },
      } = error

      this.setCsatSuccessfullAnswered(false)
      this.setError(message)
      this.setLoading(false)
    }
  }

  @Mutation
  public setError(error: string): void {
    this.error = error
  }

  @Mutation
  public setLoading(loading: boolean): void {
    this.loading = loading
  }

  @Mutation
  public setCsatSuccessfullAnswered(csatSuccessfullAnswered: boolean): void {
    this.csatSuccessfullAnswered = csatSuccessfullAnswered
  }
}

export const CsatsModule = getModule(Csats)
