






















































import { Component, Vue } from 'vue-property-decorator'
import {
  BErrorOutlineIcon,
  BRefreshIcon,
  BIcon,
  BButton,
} from '@bossabox-code/design-system'
import Sidebar from './components/sidebar/Sidebar.vue'
import ProductTitle from '@/views/projects/components/ProductTitle.vue'
import ProductProgress from '@/views/projects/components/ProductProgress.vue'
import SprintStatus from './components/sprint-status/SprintStatus.vue'

import { ProjectModule } from '@/store/modules/ProjectModule'
import { ProjectGoalsModule } from '@/store/modules/ProjectGoalsModule'

@Component({
  components: {
    BIcon,
    Sidebar,
    ProductTitle,
    BRefreshIcon,
    ProductProgress,
    BErrorOutlineIcon,
    SprintStatus,
    BButton,
  },
})
export default class Dashboard extends Vue {
  private projectStore = ProjectModule

  private goalStore = ProjectGoalsModule

  beforeMount() {
    const { projectId } = this.$route.params
    this.projectStore.requestProject(projectId)
    this.goalStore.requestGoals(projectId)
  }

  navigateToReportPreview() {
    if (this.projectStore.getProject) {
      this.$router.push({
        name: 'report-preview',
        params: { projectId: this.projectStore.getProject.id },
      })
    }
  }
}
