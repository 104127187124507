





















import { Component, Vue, Watch } from 'vue-property-decorator'
import { BNavTabs, BNavTab, BBreadcrumbs } from '@bossabox-code/design-system'
import { ProjectModule } from '@/store/modules/ProjectModule'

import {
  tabsList,
  breacrumbsList,
  IBreadcrumbs,
  mapRoutesCategory,
  ITab,
} from '@/router/config'

interface IChildrenRoute {
  path: string
  title: string
  external?: boolean
  isActiveTab?: boolean
  breadcrumb?: {
    name: string
    path: string
  }
}

@Component({
  components: {
    BNavTab,
    BNavTabs,
    BBreadcrumbs,
  },
})
export default class SecondaryMenu extends Vue {
  private activeTabCategory = ''

  private projectStore = ProjectModule

  private tabs: IChildrenRoute[] = []

  private breadcrumbs: IBreadcrumbs[] = []

  async beforeMount() {
    await this.projectStore.requestProject(this.$route.params.projectId)

    this.createNavigationOptions()
  }

  createNavigationOptions() {
    const { listTabs, defaultListTabs } = tabsList({
      params: {
        projectId: this.$route.params.projectId,
        projectName: this.projectStore.project?.name || '',
      },
    })

    const breadcrumbsList = breacrumbsList({
      params: {
        projectId: this.$route.params.projectId,
        projectName: this.projectStore.project?.name || '',
      },
    })

    const activeTab = listTabs.find(tab => this.$route.name === tab.name)

    if (
      activeTab &&
      this.$route.name !== 'task-manager' &&
      this.$route.name !== 'project-dashboard'
    ) {
      this.customOptions(activeTab, listTabs, breadcrumbsList)

      return
    }

    this.defaultOptions(defaultListTabs, breadcrumbsList)
  }

  defaultOptions(
    defaultListTabs: ITab[],
    breadcrumbsList: { [key: string]: IBreadcrumbs[] }
  ) {
    const activeTab =
      defaultListTabs.find(tab => this.$route.name === tab.name) ||
      defaultListTabs[0]

    this.tabs = defaultListTabs.map(tab => {
      if (tab.title === activeTab.title) return { ...tab, isActiveTab: true }

      return tab
    })

    this.breadcrumbs = breadcrumbsList[activeTab.category || '']
    this.activeTabCategory =
      this.projectStore.project?.name || mapRoutesCategory[activeTab.category]
  }

  customOptions(
    activeTab: ITab,
    listTabs: ITab[],
    breadcrumbsList: { [key: string]: IBreadcrumbs[] }
  ) {
    this.activeTabCategory = mapRoutesCategory[activeTab.category]

    this.tabs = listTabs
      .filter(tab => tab.category === activeTab.category)
      .map(tab => {
        if (tab.title === activeTab.title) {
          return { ...tab, isActiveTab: true }
        }

        return tab
      })

    this.breadcrumbs = breadcrumbsList[activeTab.category || '']
  }

  @Watch('$route.name')
  observableRouterName() {
    this.createNavigationOptions()
  }
}
