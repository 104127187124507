
















import { Component, Vue, Prop } from 'vue-property-decorator'
import { BCard } from '@bossabox-code/design-system'

@Component({
  components: {
    BCard,
  },
})
export default class Card extends Vue {
  @Prop({ type: String, default: '' })
  public mainStyleClass!: string

  hasHeaderSlot() {
    return !!this.$slots['header'] || !!this.$scopedSlots['header']
  }

  hasFooterSlot() {
    return !!this.$slots['footer'] || !!this.$scopedSlots['footer']
  }
}
