
































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { formatDistance } from 'date-fns'
import pt from 'date-fns/locale/pt-BR'
import Card from '@/components/Card.vue'
import { IProductJob } from '@/store/modules/ProjectModule'
import {
  BAvatar,
  BIcon,
  BStarBorderIcon,
  BDoneAllIcon,
} from '@bossabox-code/design-system'

@Component({
  components: {
    BAvatar,
    BIcon,
    BDoneAllIcon,
    BStarBorderIcon,
    Card,
  },
})
export default class JobCard extends Vue {
  @Prop({ type: Object, required: true })
  public job!: IProductJob

  get appliances() {
    return this.job.appliances
  }

  get favorites() {
    return this.job.appliances.filter(appliance => appliance.favorite)
  }

  get user() {
    return this.job.acceptedUser
  }

  get label() {
    return `${this.user ? 'Alterada' : 'Publicada'} há ${formatDistance(
      new Date(this.job.createdAt),
      new Date(),
      {
        locale: pt,
      }
    )}`
  }
}
