import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/store'
import api from '@/services/customer'

export interface IKey {
  id: string
  result: string
  measure: string
  startValue: number
  goal: number
  currentValue: number
}

export interface IGoal {
  id: string
  objective: string
  analysisPeriod: string
  keys: IKey[]
}

export interface IRequestGoalParams {
  projectId: string
  goalId: string
}

@Module({
  dynamic: true,
  namespaced: true,
  name: 'projectGoals',
  store,
})
export class ProjectGoals extends VuexModule {
  public goals: IGoal[] | null = null
  public goal: IGoal | null = null

  public error = ''

  public loading = true

  @Action
  public async requestGoals(projectId: string): Promise<void> {
    try {
      this.setLoading(true)

      const { data: goals } = await api.get(`/v1/projects/${projectId}/goals`)

      this.setGoals(goals)
      this.setLoading(false)
    } catch (err) {
      const {
        response: {
          data: { message },
        },
      } = err
      this.setLoading(false)
      this.setError(message)
    }
  }

  @Action
  public async requestGoal(params: IRequestGoalParams): Promise<void> {
    const { projectId, goalId } = params
    try {
      this.setLoading(true)

      const { data: goal } = await api.get(
        `/v1/projects/${projectId}/goals/${goalId}`
      )

      this.setGoal(goal)
      this.setLoading(false)
    } catch (err) {
      const {
        response: {
          data: { message },
        },
      } = err
      this.setLoading(false)
      this.setError(message)
    }
  }

  @Mutation
  public setError(error: string): void {
    this.error = error
  }

  @Mutation
  public setLoading(loading: boolean): void {
    this.loading = loading
  }

  @Mutation
  public setGoals(goals: IGoal[] | null): void {
    this.goals = goals
  }

  @Mutation
  public setGoal(goal: IGoal | null): void {
    this.goal = goal
  }

  public get getGoals() {
    return this.goals
  }

  public get getGoal() {
    return this.goal
  }

  public get getLoading() {
    return this.loading
  }

  public get getError() {
    return this.error
  }
}

export const ProjectGoalsModule = getModule(ProjectGoals)
