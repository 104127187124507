import Vue from 'vue'
import Vuex from 'vuex'
import { User } from '@/store/modules/UserModule'
import { Project } from '@/store/modules/ProjectModule'
import { Auth } from '@/store/modules/AuthModule'

Vue.use(Vuex)

export interface IRootState {
  userModule: User
  authModule: Auth
  projectModule: Project
}

export default new Vuex.Store<IRootState>({})
