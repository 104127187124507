import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/store'
import api from '@/services/customer'
import router from '@/router'

export interface IUserModule {
  name: string
}

export interface IPasswordCreationParams {
  token: string
  password: string
}
export interface IPasswordForgotParams {
  email: string
}

interface ICustomerUpdateParams {
  knowledge?: IKnowledge
  acceptedTerms?: boolean
  acceptedTermsAt?: Date
  acceptedTermsUserIp?: string
  acceptedTermsUserAgent?: string
}

export interface ICustomerLoginParams {
  token: string
}

interface IKnowledge {
  productPurpose?: boolean
  productProgress?: boolean
}

export interface ICustomerUserInfo {
  id: string
  name: string
  email: string
  phone: string | null
  apiId: string | null
  welcomeEmail: boolean
  knowledge: IKnowledge
  company?: ICompany
  acceptedTerms: boolean
  acceptedTermsAt?: Date
  acceptedTermsUserIp?: string
  acceptedTermsUserAgent?: string
}

interface ICompany {
  id: string
  name: string
  createdAt: string
  updatedAt: string
}

export interface ICustomerAPIError {
  status: number
  message: string
}

export const emptyCustomer: ICustomerUserInfo = {
  id: '',
  name: '',
  email: '',
  phone: null,
  apiId: null,
  welcomeEmail: false,
  acceptedTerms: false,
  knowledge: {
    productPurpose: false,
    productProgress: false,
  },
  company: {
    id: '',
    name: '',
    createdAt: '',
    updatedAt: '',
  },
}

@Module({ dynamic: true, namespaced: true, name: 'userModule', store })
export class User extends VuexModule {
  public customer = emptyCustomer

  public error = ''

  public expiredTokenMessage = 'Ops! Token inválido.'

  private loading = false

  @Action
  public async doRequestPasswordCreation(
    payload: IPasswordCreationParams
  ): Promise<void> {
    try {
      const { token, password } = payload
      await api.post('/v1/customers/password/create', {
        token,
        password,
      })
      router.push('/password/success')
    } catch (err) {
      if (err.isAxiosError) {
        const {
          response: {
            data: { message },
          },
        } = err
        if (message === this.expiredTokenMessage) {
          router.push('/password/create/expired')
        } else {
          this.setError(message)
        }
      }
    }
  }

  @Action
  public async requestUpdateCustomer(
    customerUpdateParams: ICustomerUpdateParams
  ): Promise<void> {
    try {
      this.setLoading(true)

      const { data: customer } = await api.put(
        '/v1/customers/me',
        customerUpdateParams
      )

      this.setUserData(customer)
      this.setLoading(false)
    } catch (err) {
      const {
        response: {
          data: { message },
        },
      } = err

      this.setError(message)
      this.setLoading(false)
    }
  }

  @Action
  public async doRequestForgotPassword(
    payload: IPasswordForgotParams
  ): Promise<void> {
    try {
      const { email } = payload
      await api.post('/v1/customers/password/forgot', {
        email,
      })
      if (router.currentRoute.path !== `/password/forgot/requested/${email}`) {
        router.push(`/password/forgot/requested/${email}`)
      }
    } catch (err) {
      if (err.isAxiosError) {
        const {
          response: {
            data: { message },
          },
        } = err
        this.setError(message)
      }
    }
  }

  @Action
  public async doRequestResetPassword(
    payload: IPasswordCreationParams
  ): Promise<void> {
    try {
      const { token, password } = payload
      await api.post('/v1/customers/password/reset', {
        token,
        password,
      })
      router.push('/password/reset/success')
    } catch (err) {
      if (err.isAxiosError) {
        const {
          response: {
            data: { message },
          },
        } = err
        if (message === this.expiredTokenMessage) {
          router.push('/password/reset/expired')
        } else {
          this.setError(message)
        }
      }
    }
  }

  @Action
  public async doRequestNewPassword(
    payload: IPasswordForgotParams
  ): Promise<void> {
    try {
      const { email } = payload
      await api.post('/v1/customers/password/request', {
        email,
      })
      if (router.currentRoute.path !== `/password/create/requested/${email}`) {
        router.push(`/password/create/requested/${email}`)
      }
    } catch (err) {
      if (err.isAxiosError) {
        const {
          response: {
            data: { message },
          },
        } = err
        this.setError(message)
      }
    }
  }

  @Action
  public async doRequestUserData(): Promise<void> {
    try {
      const token = localStorage.getItem('@token')

      if (!token) router.push('/')

      const { data } = await api.post('/v1/sessions', {
        token,
      })

      this.setUserData(data)
    } catch (err) {
      if (err.isAxiosError) {
        const {
          response: {
            data: { message },
          },
        } = err
        this.setError(message)
      }
    }
  }

  @Mutation
  public setError(error: string): void {
    this.error = error
  }

  @Mutation
  public setLoading(loading: boolean): void {
    this.loading = loading
  }

  @Mutation
  public setUserData(customer: ICustomerUserInfo): void {
    this.customer = customer
  }

  public get getCustomer() {
    return this.customer
  }

  public get getLoading() {
    return this.loading
  }

  public get getError() {
    return this.error
  }
}

export const UserModule = getModule(User)
