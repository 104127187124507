


























import api from '@/services/customer'
import { Component, Vue } from 'vue-property-decorator'
import { ProjectModule } from '@/store/modules/ProjectModule'

import {
  BIcon,
  BCard,
  BButton,
  BRefreshIcon,
} from '@bossabox-code/design-system'

@Component({
  components: {
    BIcon,
    BCard,
    BButton,
    BRefreshIcon,
  },
})
export default class Mission extends Vue {
  private projectStore = ProjectModule

  private mission: string | null = null

  private loading = false

  async beforeMount() {
    this.loading = true

    const { data } = await api.get(
      `/v1/projects/${this.projectStore.project?.id}/mission`
    )

    this.mission = data.mission
    this.loading = false
  }
}
