import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/store'
import api from '@/services/customer'
import { AxiosError } from 'axios'

export interface ISubTask {
  _id: string
  title: string
  done: boolean
  assigns: []
}

export interface ITask {
  _id: string
  column: string
  title: string
  order: number
  dueDate: Date
  description: string
  subTasks: ISubTask[]
}

export interface IAssignUser {
  id: string
  email: string
  fullName: string
  profilePicture: string
}

export interface IColumn {
  _id: string
  name: string
  tasks: ITask[] | []
}

export interface IBoard {
  _id: string
  apiId: string
  columns: IColumn[] | []
}

export interface ITaskCreateParams {
  boardId: string
  task: ITask
}

export interface IRequestTaskParams {
  taskId: string
  boardId: string
}

export interface ITaskUpdateParams {
  taskId: string
  task: ITask
}

export interface ITaskDeleteParams {
  taskId: string
  columnId: string
}

export interface IMoveTaskParams {
  taskId: string
  columnId: string
  position: number
}

export interface IUpdateColumnTask {
  taskId: string
  columnId: string
  position: number
}

@Module({
  dynamic: true,
  namespaced: true,
  name: 'projectTaskManager',
  store,
})
export class ProjectTaskManager extends VuexModule {
  public board: IBoard | null = null
  public columns: IColumn[] | null = null
  public task: ITask | null = null
  public users: IAssignUser[] | null = null

  public error = ''

  public loading = true
  public submitting = false

  @Action
  public async requestBoard(projectApiId: string): Promise<void> {
    try {
      this.setLoading(true)

      const { data: board } = await api.get(`/v1/boards/`, {
        params: {
          projectsId: [projectApiId],
        },
      })

      const { columns } = board

      this.setBoard(board)
      this.setColumns(columns)
      this.setLoading(false)
    } catch (err) {
      const error = err as AxiosError
      if (error.response) {
        const {
          response: {
            data: { message },
          },
        } = error
        this.setError(message)
      }

      this.setLoading(false)
    }
  }

  @Action
  public async requestTask(params: IRequestTaskParams): Promise<void> {
    const { boardId, taskId } = params
    try {
      this.setLoading(true)

      const { data: task } = await api.get(
        `/v1/boards/${boardId}/tasks/${taskId}`
      )

      this.setTask(task)
      this.setLoading(false)
    } catch (err) {
      const error = err as AxiosError
      if (error.response) {
        const {
          response: {
            data: { message },
          },
        } = error
        this.setError(message)
      }

      this.setLoading(false)
    }
  }

  @Action
  public async createTask(params: ITaskCreateParams): Promise<void> {
    const { boardId, task } = params
    try {
      this.setSubmitting(true)

      await api.post(`/v1/boards/${boardId}/tasks`, task)

      this.setSubmitting(false)
      this.setLoading(false)
    } catch (err) {
      const error = err as AxiosError
      if (error.response) {
        const {
          response: {
            data: { message },
          },
        } = error
        this.setError(message)
      }

      this.setSubmitting(false)
      this.setLoading(false)
    }
  }

  @Action
  public async updateTask(params: ITaskUpdateParams): Promise<void> {
    const { task, taskId } = params
    try {
      this.setLoading(false)
      this.setSubmitting(true)

      await api.put(`/v1/tasks/${taskId}`, task)

      this.setSubmitting(false)
    } catch (err) {
      const error = err as AxiosError
      if (error.response) {
        const {
          response: {
            data: { message },
          },
        } = error
        this.setError(message)
      }

      this.setLoading(false)
      this.setSubmitting(false)
    }
  }

  @Action
  public async deleteTask(params: ITaskDeleteParams): Promise<void> {
    const { columnId, taskId } = params
    try {
      this.setSubmitting(true)

      await api.delete(`/v1/tasks/${taskId}`)

      this.removeColumnTask({ taskId, columnId })
      this.setSubmitting(false)
      this.setLoading(false)
    } catch (err) {
      const error = err as AxiosError
      if (error.response) {
        const {
          response: {
            data: { message },
          },
        } = error
        this.setError(message)
      }

      this.setLoading(false)
    }
  }

  @Action
  public async moveTask(params: IMoveTaskParams): Promise<void> {
    const { columnId, taskId, position } = params
    try {
      await api.put(`/v1/columns/${columnId}/tasks/${taskId}`, {
        position,
      })
    } catch (err) {
      const error = err as AxiosError
      if (error.response) {
        const {
          response: {
            data: { message },
          },
        } = error
        this.setError(message)
      }
    }
  }

  @Action
  public async requestAssignUsers(boardId: string): Promise<void> {
    try {
      this.setLoading(true)

      const { data: users } = await api.get(`/v1/boards/${boardId}/users`)

      this.setAssignUsers(users)
      this.setLoading(false)
    } catch (err) {
      const error = err as AxiosError
      if (error.response) {
        const {
          response: {
            data: { message },
          },
        } = error
        this.setError(message)
      }

      this.setLoading(false)
    }
  }

  @Mutation
  public setError(error: string): void {
    this.error = error
  }

  @Mutation
  public setLoading(loading: boolean): void {
    this.loading = loading
  }

  @Mutation
  public setSubmitting(submitting: boolean): void {
    this.submitting = submitting
  }

  @Mutation
  public setColumns(columns: IColumn[] | null): void {
    this.columns = columns
  }

  @Mutation
  public setTask(task: ITask | null): void {
    this.task = task
  }

  @Mutation
  public setBoard(board: IBoard | null): void {
    this.board = board
  }

  @Mutation
  public setAssignUsers(users: IAssignUser[] | null): void {
    this.users = users
  }

  @Mutation
  public removeColumnTask(params: ITaskDeleteParams): void {
    const { columnId, taskId } = params

    this.columns =
      this.columns &&
      this.columns.map(column => {
        if (column._id === columnId) {
          column.tasks = column.tasks.filter(task => task._id !== taskId)
        }
        return column
      })
  }
}

export const ProjectTaskManagerModule = getModule(ProjectTaskManager)
