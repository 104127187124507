import axios from 'axios'

const authAPI = axios.create({
  baseURL: process.env.VUE_APP_AUTH_API_URL,
})

authAPI.interceptors.request.use(async config => {
  // const token = localStorage.getItem('@token')
  // if (token) {
  //   config.headers.Authorization = `Bearer ${token}`
  // }
  return config
})

export default authAPI
