

















































import { Component, Vue } from 'vue-property-decorator'
import {
  BNav,
  BAvatarDropdown,
  BIcon,
  BBlockIcon,
  BNavMobile,
  BNavMobileTabs
} from '@bossabox-code/design-system'
import { UserModule } from '@/store/modules/UserModule'
import { AuthModule } from '@/store/modules/AuthModule'

interface ITab {
  title: string
  url?: string
  selected?: boolean
  action?: string
}

@Component({
  components: {
    BNav,
    BAvatarDropdown,
    BIcon,
    BBlockIcon,
    BNavMobile,
    BNavMobileTabs
  }
})
export default class Navbar extends Vue {
  private userStore = UserModule
  private authStore = AuthModule
  private tabs : ITab[] = [
    {
      title: 'Projetos',
      url: '/projects',
      selected: false
    },
    {
      title: 'Sair',
      action: 'signOut',
    }
  ]

  private displayMobileMenuContent = false
  private isMobile = false

  async beforeMount() {
    if (this.userStore.getCustomer && !this.userStore.getCustomer.id) {
      await this.userStore.doRequestUserData()
    }
    
    this.checkScreenSize()
    window.addEventListener("resize", this.checkScreenSize)
  }

  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  }

  async signOut() {
    this.authStore.signOut()
  }

  handleTabAction(action: string) {
    interface IActions {
      [action: string]: Function
    }

    const mapTabAction: IActions = {
      signOut: () => {
        this.signOut()
        return true
      }
    }

    if(mapTabAction[action]) mapTabAction[action]()
  }

  activeTab(currentTab: ITab) {
    this.tabs = this.tabs.map(tab => {
      tab.selected = tab.title === currentTab.title
      return tab
    })
  }

  toggleMobileMenu() {
    this.displayMobileMenuContent = !this.displayMobileMenuContent
  }

  checkScreenSize() {
    if(screen.width < 640) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  }
}
