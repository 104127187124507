






































































import { Component, Vue } from 'vue-property-decorator'
import {
  BTextarea,
  BButton,
  BNewBIconStarOutline,
  BNewBIconStar,
} from '@bossabox-code/design-system'
import Card from '@/components/Card.vue'
import { CsatsModule } from '@/store/modules/CsatsModule'
import CsatAlreadyAnswered from './CsatAlreadyAnswered.vue'
import CsatSuccessfullyAnswered from './CsatSuccessfullyAnswered.vue'
import CsatAnswerError from './CsatAnswerError.vue'

interface IToast {
  show: Function
}

@Component({
  components: {
    Card,
    BTextarea,
    BButton,
    BNewBIconStarOutline,
    BNewBIconStar,
    CsatAlreadyAnswered,
    CsatSuccessfullyAnswered,
    CsatAnswerError,
  },
})
export default class CsatForm extends Vue {
  public score = 0
  public feedback = ''
  private csatsStore = CsatsModule

  $toasts!: IToast

  get isCsatAlreadyAnswered() {
    return this.csatsStore.error === 'CSAT already answered.'
  }

  get isCsatAnswerError() {
    return (
      this.csatsStore.error &&
      this.csatsStore.error !== 'CSAT already answered.'
    )
  }

  get isCsatSuccessfullyAnswered() {
    return this.csatsStore.csatSuccessfullAnswered
  }

  handleScoreChange(index: number) {
    this.score = index
  }

  validateCsatAnswer() {
    if (this.score === 0) {
      this.$toasts.show({
        message: 'Você precisa selecionar uma nota!',
        timeout: 3000,
        type: 'danger',
      })

      return false
    }
    return true
  }

  async sendProjectCsatByEmail() {
    const { token } = this.$route.query

    if (!this.validateCsatAnswer()) return

    await this.csatsStore.sendCsatEmailAnswer({
      token,
      score: this.score,
      feedback: this.feedback,
    })
  }
}
