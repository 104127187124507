













import { Component, Vue } from 'vue-property-decorator'
import { BTaskManagerForm } from '@bossabox-code/shared-components'
import {
  ITask,
  ProjectTaskManagerModule,
} from '@/store/modules/ProjectTaskManagerModule'

interface IToast {
  show: Function
}

@Component({
  components: {
    BTaskManagerForm,
  },
})
export default class AddTask extends Vue {
  private taskManagerStore = ProjectTaskManagerModule
  $toasts!: IToast

  async beforeMount() {
    const { boardId } = this.$route.params
    await this.taskManagerStore.setLoading(false)
    await this.taskManagerStore.requestAssignUsers(boardId)
  }

  async saveTask(task: ITask) {
    const { boardId } = this.$route.params

    await this.taskManagerStore.createTask({ boardId, task })

    if (!this.taskManagerStore.error) {
      this.$toasts.show({
        title: 'Tarefa adicionada com sucesso!',
        timeout: 5000,
        type: 'success',
      })
    } else {
      this.$toasts.show({
        title: 'Não foi possível criar a tarefa',
        message: this.taskManagerStore.error,
        timeout: 5000,
        type: 'danger',
      })
    }
  }
}
