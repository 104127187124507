


























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { BModal, BIcon, BDoneIcon } from '@bossabox-code/design-system'

@Component({
  components: {
    BIcon,
    BModal,
    BDoneIcon,
  },
})
export default class ProductProgressInfoModal extends Vue {
  $refs!: {
    modal: HTMLFormElement
  }

  @Prop({ type: Boolean, default: false })
  private openOrClosedModal!: boolean

  @Watch('openOrClosedModal')
  showModal() {
    this.openOrClosedModal
      ? this.$refs.modal.showModal()
      : this.$refs.modal.hideModal()
  }
}
