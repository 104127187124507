













































































import { Component, Vue } from 'vue-property-decorator'
import {
  BTextarea,
  BButton,
  BIcon,
  BRefreshIcon,
  BContextBanner,
  BToggle,
} from '@bossabox-code/design-system'
import { ProjectModule } from '@/store/modules/ProjectModule'
import { UserModule } from '@/store/modules/UserModule'
import { ProjectReportModule } from '@/store/modules/ProjectReportModule'
import Iframe from '@/components/Iframe.vue'
import { debounce } from 'lodash'

@Component({
  components: {
    BTextarea,
    BButton,
    BIcon,
    BRefreshIcon,
    BContextBanner,
    Iframe,
    BToggle,
  },
})
export default class ReportPreview extends Vue {
  private additionalInformations = ''
  private projectStore = ProjectModule
  private projectReport = ProjectReportModule
  private userStore = UserModule
  private projectId = ''

  private loading = false
  private error = false
  private pdfLink = ''

  private debouncedRequestProjectPreview!: Function

  created() {
    this.debouncedRequestProjectPreview = debounce(
      this.requestProjectPreview,
      3000
    )
  }

  debouncedAdditionalInformationsInput() {
    this.debouncedRequestProjectPreview()
  }

  async requestProjectPreview() {
    this.pdfLink = ''

    await this.projectReport.requestProjectReport({
      projectId: this.projectId,
      additionalInformations: this.additionalInformations,
    })

    this.pdfLink = this.projectReport.getProjectReport
  }

  async beforeMount() {
    const { projectId } = this.$route.params
    this.projectId = projectId

    this.requestProjectAndUserData()
    this.requestProjectPreview()
  }

  requestProjectAndUserData() {
    this.projectStore.requestProject(this.projectId)
    if (this.userStore.getCustomer && !this.userStore.getCustomer.id) {
      this.userStore.doRequestUserData()
    }
  }

  async downloadProjectReport() {
    try {
      const fileURL = this.projectReport.getProjectReport
      const link = document.createElement('a')

      link.href = fileURL
      link.setAttribute('download', 'resumo.pdf')
      document.body.appendChild(link)
      link.click()

      this.loading = false
      this.error = false

      return fileURL
    } catch (error) {
      this.loading = false
      this.error = true
      console.error(error)
    }
  }
}
