






















































import { Component, Vue } from 'vue-property-decorator'
import {
  BInput,
  BIcon,
  BButton,
  BContextBanner,
  BErrorIcon,
  BBoxLogoIcon,
} from '@bossabox-code/design-system'
import { UserModule } from '@/store/modules/UserModule'

@Component({
  components: {
    BInput,
    BButton,
    BIcon,
    BContextBanner,
    BErrorIcon,
    BBoxLogoIcon,
  },
})
export default class ForgotPassword extends Vue {
  private email = ''
  private emailError = ''
  private loading = false
  private userStore = UserModule

  async forgotPassword() {
    if (!this.email) {
      this.emailError = 'Lembre-se de preencher os campos'
      return
    }

    try {
      this.loading = true
      await this.userStore.doRequestForgotPassword({
        email: this.email,
      })
      this.loading = false
    } catch {
      this.loading = false
    }
  }
}
