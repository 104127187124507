




































































































import { Component, Vue } from 'vue-property-decorator'
import {
  BTable,
  BIcon,
  BButton,
  BCommentIcon,
  BRefreshIcon,
  BFolderIcon,
  BCollapse,
  BCard,
  BSelect,
  BChevronDownIcon,
  BFavoriteIcon,
  BFavoriteBorderIcon,
  BProgressBar,
} from '@bossabox-code/design-system'
import Comments from './Comments.vue'
import { ProjectModule } from '@/store/modules/ProjectModule'
import { ProjectGoalsModule, IKey } from '@/store/modules/ProjectGoalsModule'

@Component({
  components: {
    BTable,
    BIcon,
    BButton,
    BCommentIcon,
    BRefreshIcon,
    BFolderIcon,
    BSelect,
    BCollapse,
    BCard,
    BChevronDownIcon,
    BFavoriteIcon,
    BFavoriteBorderIcon,
    BProgressBar,
    Comments,
  },
})
export default class Goal extends Vue {
  private goalStore = ProjectGoalsModule
  private projectStore = ProjectModule

  async beforeMount() {
    const { projectId, goalId } = this.$route.params
    await this.projectStore.requestProject(projectId)
    await this.goalStore.requestGoal({ projectId, goalId })
  }

  beforeDestroy() {
    this.goalStore.setGoals(null)
  }

  progressColor(value: number) {
    if (value >= 0 && value <= 33) {
      return 'red'
    } else if (value >= 34 && value <= 66) {
      return 'yellow'
    } else if (value >= 67 && value <= 100) {
      return 'green'
    }
    return 'grey'
  }

  progressCalc(key: IKey) {
    const goalResult = key.goal - key.startValue

    return goalResult > 0
      ? (((key.currentValue - key.startValue) * 100) / goalResult).toFixed(0)
      : 0
  }

  get averageData() {
    if (
      this.goalStore.goal &&
      this.goalStore.goal.keys &&
      this.goalStore.goal.keys.length > 0
    ) {
      const startTotal = this.goalStore.goal.keys.reduce(
        (result, key) => result + key.startValue,
        0
      )
      const goalTotal = this.goalStore.goal.keys.reduce(
        (result, key) => result + key.goal,
        0
      )
      const currTotal = this.goalStore.goal.keys.reduce(
        (result, key) => result + key.currentValue,
        0
      )

      const goalResultTotal = goalTotal - startTotal
      const percentTotal =
        goalResultTotal > 0
          ? parseInt(
              (((currTotal - startTotal) * 100) / goalResultTotal).toFixed(0)
            )
          : 0

      return {
        goalTotal,
        currTotal,
        percentTotal,
        color: this.progressColor(percentTotal),
      }
    }
    return {}
  }
}
