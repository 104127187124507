






























import { Component, Vue } from 'vue-property-decorator'
import { BButton, BIllustrationKanbanMan } from '@bossabox-code/design-system'

@Component({
  components: {
    BButton,
    BIllustrationKanbanMan,
  },
})
export default class CallToAction extends Vue {
  private isFirstAccess = true

  beforeMount() {
    const hasFirstAccessTokenRegistry = JSON.parse(
      localStorage.getItem('@isKanbanFirstAccess') || 'null'
    )

    if (hasFirstAccessTokenRegistry !== null) {
      this.isFirstAccess = hasFirstAccessTokenRegistry

      this.$emit('isFirstAccess', this.isFirstAccess)
    }
  }

  setFirstAccess() {
    localStorage.setItem('@isKanbanFirstAccess', String(false))
    this.isFirstAccess = false

    this.$emit('isFirstAccess', this.isFirstAccess)
  }
}
