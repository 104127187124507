





























import { Component, Vue } from 'vue-property-decorator'
import { BTaskManagerForm } from '@bossabox-code/shared-components'
import {
  ITask,
  ProjectTaskManagerModule,
} from '@/store/modules/ProjectTaskManagerModule'
import { BIcon, BRefreshIcon } from '@bossabox-code/design-system'

interface IToast {
  show: Function
}

@Component({
  components: {
    BTaskManagerForm,
    BIcon,
    BRefreshIcon,
  },
})
export default class EditTask extends Vue {
  private taskManagerStore = ProjectTaskManagerModule
  $toasts!: IToast

  async beforeMount() {
    const { taskId, boardId } = this.$route.params
    await this.taskManagerStore.requestTask({ boardId, taskId })
    await this.taskManagerStore.requestAssignUsers(boardId)
  }

  async saveTask(task: ITask) {
    const { taskId } = this.$route.params
    await this.taskManagerStore.updateTask({ taskId, task })

    if (!this.taskManagerStore.error) {
      this.$toasts.show({
        title: 'Tarefa atualizada com sucesso!',
        timeout: 5000,
        type: 'success',
      })
    } else {
      this.$toasts.show({
        title: 'Não foi possível criar a tarefa',
        message: this.taskManagerStore.error,
        timeout: 5000,
        type: 'danger',
      })
    }
  }
}
