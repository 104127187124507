





















































import { Component, Vue } from 'vue-property-decorator'
import {
  BInput,
  BIcon,
  BButton,
  BContextBanner,
  BErrorIcon,
  BBoxLogoIcon,
} from '@bossabox-code/design-system'
import { UserModule } from '@/store/modules/UserModule'

@Component({
  components: {
    BInput,
    BButton,
    BIcon,
    BContextBanner,
    BErrorIcon,
    BBoxLogoIcon,
  },
})
export default class ExpiredPassword extends Vue {
  private email = ''
  private source = ''
  private store = UserModule

  beforeMount() {
    const { source } = this.$route.meta
    if (source) this.source = source
  }

  async forgotPassword() {
    if (this.email) {
      if (this.source === 'create') {
        await this.store.doRequestNewPassword({
          email: this.email,
        })
      } else {
        await this.store.doRequestForgotPassword({
          email: this.email,
        })
      }
    }
  }
}
