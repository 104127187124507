












































import { Component, Vue } from 'vue-property-decorator'
import { ProjectGoalsModule } from '@/store/modules/ProjectGoalsModule'
import GoalsTable from './GoalsTable.vue'
import {
  BCard,
  BRefreshIcon,
  BButton,
  BIcon,
  BPollIcon,
} from '@bossabox-code/design-system'

@Component({
  components: {
    BCard,
    BButton,
    BIcon,
    BRefreshIcon,
    BPollIcon,
    GoalsTable,
  },
})
export default class Goals extends Vue {
  private goalStore = ProjectGoalsModule

  async beforeMount() {
    const { projectId } = this.$route.params
    await this.goalStore.requestGoals(projectId)
  }

  openGuidePDF() {
    window.open(
      'https://bossabox-uploads.s3.sa-east-1.amazonaws.com/Guia+OKRs.pdf'
    )
  }
}
