





























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { BButton, BRefreshIcon, BIcon } from '@bossabox-code/design-system'
import Card from '@/components/Card.vue'

import { ProjectModule } from '@/store/modules/ProjectModule'

@Component({
  components: {
    Card,
    BIcon,
    BRefreshIcon,
    BButton,
  },
})
export default class Iframe extends Vue {
  private projectStore = ProjectModule
  private isUrlLoading = true

  $refs!: {
    iframe: HTMLFormElement
  }

  async mounted() {
    this.$refs.iframe.onload = () => {
      this.isUrlLoading = false
    }
  }

  @Watch('url')
  async observableIframeLink() {
    this.isUrlLoading = true
  }

  @Prop({ type: String, default: '' })
  public url!: string

  @Prop({ type: String, default: '' })
  public iframeClass!: string

  @Prop({ type: Boolean, default: false })
  public isIframeWaitingForStoreLoading!: boolean
}
