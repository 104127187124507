

















import { Component, Vue, Watch } from 'vue-property-decorator'
import { BAppToast } from '@bossabox-code/design-system'

import TermsAndPoliciesModal from '@/components/TermsAndPoliciesModal.vue'
import { UserModule } from '@/store/modules/UserModule'

@Component({
  components: {
    BAppToast,
    TermsAndPoliciesModal,
  },
})
export default class App extends Vue {
  private userStore = UserModule
  private modalIsOpen = false

  created() {
    document.title = 'Para Empresas — BossaBox'
  }

  get layout() {
    const layout = this.$route.meta.layout
    return layout ? `${layout}-layout` : 'div'
  }

  @Watch('userStore.getCustomer')
  showTermsAndPoliciesModal() {
    const { id, acceptedTerms } = this.userStore.getCustomer
    if (id && !acceptedTerms) {
      this.$nextTick(() => {
        this.modalIsOpen = true
      })
    }
  }
}
