export enum ERoutesCategory {
  PEOPLE = 'project-people',
  RESULTS = 'results',
  TASK_MANAGER = 'task-manager',
  PROJECT_DASHBOARD = 'project-dashboard',
}

export const mapRoutesCategory: { [key: string]: string } = {
  [ERoutesCategory.PEOPLE]: 'Pessoas',
  [ERoutesCategory.RESULTS]: 'Resultados',
  [ERoutesCategory.TASK_MANAGER]: 'task-manager',
  [ERoutesCategory.PROJECT_DASHBOARD]: 'project-dashboard',
}
