export function progressBarColor(value: number) {
  if (value >= 0 && value <= 33) {
    return 'red'
  } else if (value >= 34 && value <= 66) {
    return 'yellow'
  } else if (value >= 67 && value <= 100) {
    return 'green'
  }

  return 'grey'
}
