































import { Component, Vue, Prop } from 'vue-property-decorator'
import { BModal, BButton } from '@bossabox-code/design-system'

@Component({
  components: {
    BModal,
    BButton,
  },
})
export default class CancelModal extends Vue {
  $refs!: {
    cancelModal: HTMLFormElement
  }

  @Prop({ type: String, default: '' })
  public description!: string

  @Prop({ type: String, default: '' })
  public title!: string

  @Prop({ type: String, default: 'Cancelar' })
  public cancelButton!: string

  @Prop({ type: String, default: 'Confirmar' })
  public confirmButton!: string

  showModal() {
    this.$refs.cancelModal.showModal()
  }
  close() {
    this.$refs.cancelModal.hideModal()
    this.$emit('close')
  }
  confirm() {
    this.$refs.cancelModal.hideModal()
    this.$emit('confirmed')
  }
  hideModal() {
    this.$refs.cancelModal.hideModal()
  }
  toggleModal() {
    this.$refs.cancelModal.toggleModal()
  }
}
