




















import { Component, Vue } from 'vue-property-decorator'
import { BLink, BButton } from '@bossabox-code/design-system'

@Component({
  components: {
    BLink,
    BButton,
  },
})
export default class CloseHeaderBasic extends Vue {}
