

































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  BIcon,
  BModal,
  BInput,
  BButton,
  BSelect,
  BTextarea,
  BDeleteIcon,
  BRefreshIcon,
} from '@bossabox-code/design-system'

import {
  metricTypes,
  metricTypesValues,
  metricTypesDescription,
  getMetricSubtiblesAndExamples,
  parseTypeToConstant,
} from './utils/MapMetricTypes'

import { IMetric } from '@/store/modules/ProjectOtherMetricsModule/interfaces'

import { UserModule } from '@/store/modules/UserModule'
import { ProjectOtherMetricsModule } from '@/store/modules/ProjectOtherMetricsModule'

interface IRequestModal {
  metric: IMetric | null
  typesThatCanBeCreated: string[] | undefined
}

@Component({
  components: {
    BIcon,
    BModal,
    BInput,
    BSelect,
    BButton,
    BTextarea,
    BDeleteIcon,
    BRefreshIcon,
  },
  data() {
    return {
      metricTypes,
      metricTypesValues,
      metricTypesDescription,
    }
  },
})
export default class MetricsModal extends Vue {
  $refs!: {
    modal: HTMLFormElement
  }

  private metric: IMetric | null = null

  private metricDescription = ''

  private metricSubtitle = ''

  private metricTypeSelected = ''

  private metricExamples: string[] = []

  private typesThatCanBeCreated = metricTypesValues

  private metricsStore = ProjectOtherMetricsModule

  private userStore = UserModule

  private setValues(metric: IMetric) {
    this.metricTypeSelected = metricTypes[metric.type]
    this.metricDescription = metric.description
    this.metric = metric

    const metricFormated = getMetricSubtiblesAndExamples(
      this.metricTypeSelected
    )

    if (metricFormated) {
      this.metricExamples = metricFormated.examples
      this.metricSubtitle = metricFormated.subtitle
    }
  }

  async handleDelete(metricId: string) {
    const { projectId } = this.$route.params
    await this.metricsStore.deleteMetric({ metricId, projectId })
    this.$refs.modal.hideModal()
  }

  async handleSubmit() {
    const { projectId } = this.$route.params
    const typeInConstant = parseTypeToConstant(this.metricTypeSelected)

    if (typeInConstant) {
      if (this.metric?.id) {
        await this.metricsStore.updateMetric({
          projectId,
          type: typeInConstant,
          metricId: this.metric.id,
          description: this.metricDescription,
          lastUserUpdated: this.userStore.getCustomer.name,
        })

        this.$refs.modal.hideModal()

        return
      }

      await this.metricsStore.createMetric({
        projectId,
        type: typeInConstant,
        description: this.metricDescription,
        lastUserUpdated: this.userStore.getCustomer.name,
      })

      this.metricSubtitle = ''
      this.metricDescription = ''
      this.metricTypeSelected = ''
      this.$refs.modal.hideModal()
    }
  }

  openModal({ metric, typesThatCanBeCreated }: IRequestModal) {
    if (metric) this.setValues(metric)

    if (typesThatCanBeCreated && typesThatCanBeCreated.length)
      this.typesThatCanBeCreated = typesThatCanBeCreated

    this.$refs.modal.showModal()
  }

  get formatMetricExamples() {
    return `Exemplos: ${this.metricExamples.join(', ')}.`
  }

  @Watch('metricTypeSelected')
  metricDescriptonByTypeSelected() {
    const metric = getMetricSubtiblesAndExamples(this.metricTypeSelected)

    if (metric) {
      this.metricSubtitle = metric.subtitle
      this.metricExamples = metric.examples
    }
  }
}
