


















































import { Component, Vue } from 'vue-property-decorator'
import { ProjectModule } from '@/store/modules/ProjectModule'
import {
  ITask,
  ProjectTaskManagerModule,
} from '@/store/modules/ProjectTaskManagerModule'
import CallToAction from './components/call-to-action/CallToAction.vue'
import { BTaskManager } from '@bossabox-code/shared-components'
import { BIcon, BRefreshIcon } from '@bossabox-code/design-system'
import RemoveTask from '@/components/CancelModal.vue'

interface ITaskMoveParams {
  taskId: string
  columnId: string
  position: number
}

@Component({
  components: {
    CallToAction,
    BTaskManager,
    BIcon,
    BRefreshIcon,
    RemoveTask,
  },
})
export default class TaskManager extends Vue {
  private projectStore = ProjectModule
  private taskManagerStore = ProjectTaskManagerModule
  private isFirstAccess = true
  private taskToRemove: ITask | null = null

  $refs!: {
    removeModal: HTMLFormElement
  }

  async beforeMount() {
    this.taskManagerStore.setLoading(true)

    const { projectId } = this.$route.params
    await this.projectStore.requestProject(projectId)

    if (this.projectStore.getProject && this.projectStore.getProject.apiId) {
      await this.taskManagerStore.requestBoard(
        this.projectStore.getProject.apiId
      )
    }
  }

  setIsFirstAccess(value: boolean) {
    this.isFirstAccess = value
  }

  addTaskButtonAction() {
    if (this.taskManagerStore.board) {
      this.$router.push({
        name: 'task-manager-add-task',
        params: {
          projectId: this.$route.params.projectId,
          boardId: this.taskManagerStore.board._id,
        },
      })
    }
  }

  editTaskButtonAction(task: ITask) {
    if (this.taskManagerStore.board && task) {
      this.$router.push({
        name: 'task-manager-edit-task',
        params: {
          projectId: this.$route.params.projectId,
          boardId: this.taskManagerStore.board._id,
          taskId: task._id,
        },
      })
    }
  }

  removeTaskButtonAction(task: ITask) {
    if (this.$refs.removeModal) {
      this.taskToRemove = task
      this.$refs.removeModal.showModal()
    }
  }

  async confirmRemove() {
    if (this.taskToRemove) {
      this.taskManagerStore.deleteTask({
        taskId: this.taskToRemove._id,
        columnId: this.taskToRemove.column,
      })
    }
  }

  async moveTaskAction(params: ITaskMoveParams) {
    await this.taskManagerStore.moveTask(params)
  }
}
