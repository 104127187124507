export const EMAIL_SENT = {
  SUCCESS: {
    TITLE: 'E-mail enviado novamente com sucesso',
    MESSAGE:
      'O e-mail chegará em até 5 minutos. Não esqueça de checar sua caixa de spam.',
  },
}

export const CEREMONIES = {
  PLANNING: {
    title: 'Planning',
    goal:
      'Analisar o Roadmap do Produto e definir objetivos para a Sprint seguinte.',
    schedule: 'Ao final da Sprint atual.',
    participants:
      'Equipe de desenvolvimento, Product Manager e Product Designer.',
    duration: 'Aproximadamente 1 hora. ',
    description: `O(A) Product Manager avalia, junto com o time, quais histórias serão entregues 
      no próximo ciclo. A partir dessa decisão, planejam como fazer isso acontecer: tarefas, 
      agenda e estratégia geral da Sprint.`,
  },
  DAILY: {
    title: 'Daily',
    goal: 'Manter a equipe informada sobre o que está acontecendo.',
    schedule: 'Uma vez por dia.',
    participants:
      'Equipe de desenvolvimento, Product Manager e Product Designer.',
    duration: 'Aproximadamente 15 minutos. ',
    description: `Cada pessoa do time compartilha os seus avanços e/ou impedimentos em relação ao dia anterior, 
      para que a equipe tire dúvidas e planeje o dia corrente. Nesta cerimônia, é sempre importante 
      o/a Product Manager relembrar o time sobre os objetivos da Sprint.`,
  },
  REVIEW: {
    title: 'Review',
    goal:
      'Apresentar resultados, comemorar conquistas e alinhar os próximos passos.',
    schedule: 'No final da sprint.',
    participants:
      'Equipe de desenvolvimento, Product Manager,Product Designer e Cliente.',
    duration: 'Aproximadamente 1 hora.',
    description: `O Product Manager conduz a reunião, apresentando estratégia do produto
     e objetivos da sprint e, na sequência, passa a palavra para o Product Designer e 
     Líder Técnico demonstrarem seus avanços em discovery e delivery, respectivamente.`,
  },
  RETROBOX: {
    title: 'Retrobox',
    goal: 'Coletar aprendizados e impressões a respeito da Sprint entregue.',
    schedule: 'Ao final de cada Sprint, logo após a Review.',
    participants: 'Cliente e BossaBox.',
    duration: 'Aproximadamente 30 minutos.',
    description: `A CS responsável pelo projeto dialoga com o cliente sobre o progresso alcançado
     na Sprint que se encerrou. É um momento para alinhar expectativas e desenhar planos de ação.`,
  },
}
