




















































































import { Component, Vue } from 'vue-property-decorator'
import {
  BInput,
  BButton,
  BContextBanner,
  BLink,
  BBoxLogoIcon,
  BIcon,
  BRefreshIcon,
  BErrorIcon,
} from '@bossabox-code/design-system'
import { AuthModule } from '@/store/modules/AuthModule'
import { UserModule } from '@/store/modules/UserModule'

@Component({
  components: {
    BInput,
    BButton,
    BContextBanner,
    BLink,
    BBoxLogoIcon,
    BIcon,
    BErrorIcon,
    BRefreshIcon,
  },
})
export default class Login extends Vue {
  private email = ''
  private password = ''
  private auth = AuthModule
  private user = UserModule

  fieldsAreValid() {
    return !!(this.email && this.password)
  }

  async signIn() {
    if (this.fieldsAreValid()) {
      this.auth.doLoginAuth({
        email: this.email,
        password: this.password,
      })
    }
  }
}
