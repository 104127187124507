const pages = [
  {
    routerName: 'list-projects',
    eventName: 'Projects List Viewed',
  },
  {
    routerName: 'expanded-board',
    eventName: 'Project Product Expanded Roadmap Viewed',
  },
  {
    routerName: 'report-preview',
    eventName: 'Project Report Preview Viewed',
  },
  {
    routerName: 'project-dashboard',
    eventName: 'Projects Dashboard Viewed',
  },
  {
    routerName: 'project-people',
    eventName: 'Project Squad Viewed',
  },
  {
    routerName: 'results',
    eventName: 'Project Results Viewed',
  },
]

export interface IPageViewName {
  routerName: string
  eventName: string
}

export const getEventNameByRouter = (value: string) => {
  const page = pages.find((page: IPageViewName) => page.routerName === value)
  return page ? page.eventName : null
}
