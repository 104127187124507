




















































































import { Component, Vue } from 'vue-property-decorator'
import { BIcon, BRefreshIcon, BEmptyState } from '@bossabox-code/design-system'
import {
  ProjectModule,
  IProductJob,
  ISquadMembers,
} from '@/store/modules/ProjectModule'
import JobCard from '@/views/projects/people/components/JobCard.vue'
import MemberInfoModal from '@/views/projects/people/components/MemberInfoModal.vue'
import JobInfoModal from '@/views/projects/people/components/JobInfoModal.vue'

@Component({
  components: {
    BIcon,
    BRefreshIcon,
    JobCard,
    MemberInfoModal,
    JobInfoModal,
    BEmptyState,
  },
})
export default class ProjectPeople extends Vue {
  public modalIsOpen = false
  public jobModalIsOpen = false
  public selectedMember: ISquadMembers | null = null
  public selectedJob: IProductJob | null = null
  private projectStore = ProjectModule

  async beforeMount() {
    const { projectId } = this.$route.params
    await this.projectStore.requestProject(projectId)
  }

  filterProjectJobs(filterBy: string) {
    return (
      this.projectStore.getProject?.jobs
        .filter(job => job.status === filterBy)
        .sort((a, b) => (a.role > b.role ? 1 : -1)) || []
    )
  }

  get publicJobs() {
    return this.filterProjectJobs('public')
  }

  get allocatedJobs() {
    return this.filterProjectJobs('allocated')
  }

  showModal(job: IProductJob, modal: string) {
    if (modal === 'allocatedJobs') {
      this.selectedMember = job.acceptedUser
      this.modalIsOpen = !this.modalIsOpen ?? true
    } else {
      this.selectedJob = job
      this.jobModalIsOpen = !this.jobModalIsOpen ?? true
    }
  }

  hideModal(modal: string) {
    modal === 'allocatedJobs'
      ? (this.modalIsOpen = false)
      : (this.jobModalIsOpen = false)
  }
}
