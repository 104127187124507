



























import { Component, Vue } from 'vue-property-decorator'
import { BButton, BIcon } from '@bossabox-code/design-system'

import IconBossabox from '@bossabox-code/design-system/icons/icon-bossabox.svg'

@Component({
  components: {
    BButton,
    BIcon,
    IconBossabox,
  },
})
export default class ResetPasswordUpdated extends Vue {
  goToLogin() {
    this.$router.push('/')
  }
}
