import store from '@/store'
import api from '@/services/customer'

import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'

import {
  IMetric,
  ICreateProjectMetricParams,
  IUpdateProjectMetricParams,
  IDeleteProjectMetricParams,
} from './interfaces'

@Module({
  dynamic: true,
  namespaced: true,
  name: 'projectOtherMetrics',
  store,
})
export class ProjectOtherMetrics extends VuexModule {
  private _error = ''

  private _loading = true

  private _metrics: IMetric[] = []

  @Action
  public async requestMetrics(projectId: string): Promise<void> {
    try {
      this.setLoading(true)

      const { data: metrics } = await api.get(
        `/v1/projects/${projectId}/metrics`
      )

      this.setMetrics(metrics)
      this.setLoading(false)
    } catch (err) {
      const {
        response: {
          data: { message },
        },
      } = err

      this.setLoading(false)
      this.setError(message)
    }
  }

  @Action
  public async createMetric(data: ICreateProjectMetricParams): Promise<void> {
    try {
      this.setLoading(true)

      const { data: metric } = await api.post(
        `/v1/projects/${data.projectId}/metrics`,
        data
      )

      this.setMetrics([...this.metrics, metric])
      this.setLoading(false)
    } catch (err) {
      const {
        response: {
          data: { message },
        },
      } = err

      this.setLoading(false)
      this.setError(message)
    }
  }

  @Action
  public async updateMetric(data: IUpdateProjectMetricParams): Promise<void> {
    try {
      this.setLoading(true)

      const { data: metric } = await api.put(
        `/v1/projects/${data.projectId}/metrics/${data.metricId}`,
        data
      )

      this.setMetric(metric)
      this.setLoading(false)
    } catch (err) {
      const {
        response: {
          data: { message },
        },
      } = err

      this.setLoading(false)
      this.setError(message)
    }
  }

  @Action
  public async deleteMetric(data: IDeleteProjectMetricParams): Promise<void> {
    try {
      this.setLoading(true)

      await api.delete(
        `/v1/projects/${data.projectId}/metrics/${data.metricId}`
      )

      this.setMetrics(
        this.metrics.filter(metric => metric.id !== data.metricId)
      )
      this.setLoading(false)
    } catch (err) {
      const {
        response: {
          data: { message },
        },
      } = err

      this.setLoading(false)
      this.setError(message)
    }
  }

  public get metrics() {
    return this._metrics
  }

  @Mutation
  public setMetric(metric: IMetric): void {
    this._metrics = this._metrics.map(actual => {
      if (actual.id === metric.id) return metric

      return actual
    })
  }

  @Mutation
  public setMetrics(metrics: IMetric[]): void {
    this._metrics = metrics
  }

  public get loading() {
    return this._loading
  }

  @Mutation
  public setError(error: string): void {
    this._error = error
  }

  public get error() {
    return this._error
  }

  @Mutation
  public setLoading(loading: boolean): void {
    this._loading = loading
  }
}

export const ProjectOtherMetricsModule = getModule(ProjectOtherMetrics)
