





















import { Component, Prop, Vue } from 'vue-property-decorator'

import customerApi from '@/services/customer'
import { UserModule } from '@/store/modules/UserModule'
import { BCommentCard, BListComments } from '@bossabox-code/shared-components'

interface IComment {
  id: string
  comment: string
  viewed: boolean
  createdAt: string
  viewedBy: string[]
  createdBy: {
    userId: string
    userName: string
    platform: string
  }
}

interface IInfiniteLoading extends Element {
  state: {
    loaded: () => void
    complete: () => void
  }
}

@Component({
  components: {
    BListComments,
    BCommentCard,
  },
})
export default class Comments extends Vue {
  $refs!: {
    infiniteLoading: IInfiniteLoading
  }

  private page = 1

  private comment = ''

  private loading = false

  private userStore = UserModule

  private comments: IComment[] = []

  @Prop({ type: String, required: true })
  private projectId!: string

  @Prop({ type: String, required: true })
  private goalId!: string

  async beforeMount() {
    await this.handleList()
  }

  async handleSubmit(value: string) {
    if (!value) return

    this.comment = value
    this.loading = true

    const { data: comment } = await customerApi.post(
      `v1/projects/${this.projectId}/goals/${this.goalId}/comments`,
      {
        comment: this.comment,
      }
    )

    this.comment = ''
    this.loading = false
    this.comments.push(comment)

    await this.$nextTick()
    this.moveScrollToComment(comment.id)
  }

  moveScrollToComment(commentId: string) {
    const el = document.getElementById(commentId)

    if (el)
      el.scrollIntoView({
        inline: 'start',
        block: 'nearest',
        behavior: 'smooth',
      })
  }

  async handleList() {
    if (!this.loading) {
      this.loading = true

      const {
        data: { comments },
      } = await customerApi.get(
        `v1/projects/${this.projectId}/goals/${this.goalId}/comments`,
        {
          params: {
            page: this.page,
            userId: this.userStore.customer.apiId,
          },
        }
      )

      if (!comments.length) {
        this.loading = false
        this.$refs.infiniteLoading.state.complete()

        return
      }

      this.page += 1
      this.comments.unshift(...comments.reverse())

      this.loading = false
      this.$refs.infiniteLoading.state.loaded()
    }
  }

  isNotViewed(viewed: string, customerId: string) {
    const wasNotCreatedByCurrentUser =
      customerId !== this.userStore.customer.apiId

    return wasNotCreatedByCurrentUser && !viewed
      ? 'border border-blue-lighter-2'
      : ''
  }
}
