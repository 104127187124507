































































import { Component, Vue } from 'vue-property-decorator'
import {
  BInput,
  BButton,
  BContextBanner,
  BErrorIcon,
  BBoxLogoIcon,
} from '@bossabox-code/design-system'
import { UserModule } from '@/store/modules/UserModule'
import tokenIsValid from '@/helpers/validateToken'

@Component({
  components: {
    BInput,
    BButton,
    BContextBanner,
    BErrorIcon,
    BBoxLogoIcon,
  },
})
export default class CreatePassword extends Vue {
  private password = ''
  private passwordConfirmation = ''
  private token = ''
  private store = UserModule

  fieldsAreValid() {
    return (
      this.password &&
      this.passwordConfirmation &&
      this.password.length >= 8 &&
      this.password === this.passwordConfirmation
    )
  }

  beforeMount() {
    const token = this.$route.query.token as string
    if (token && tokenIsValid(token)) {
      this.token = token
    } else {
      this.$router.push('/password/create/expired')
    }
  }

  async createPassword() {
    if (this.fieldsAreValid()) {
      await this.store.doRequestPasswordCreation({
        token: this.token,
        password: this.password,
      })
    }
  }
}
